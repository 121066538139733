<script setup lang="ts">
import { useCmsHead } from "@/composables/useCmsHead";
import type { Ref } from "vue";
import { useCategorySearch } from "#imports";
import type { Schemas } from "#shopware";
import {useCachedCategory} from "~/components/server/useCachedCategory";

const props = defineProps<{
  navigationId: string;
}>();

const { search } = useCategorySearch();
const route = useRoute();
const { buildDynamicBreadcrumbs } = useBreadcrumbs();
const { apiClient } = useShopwareContext();

const categoryResponse = ref();
categoryResponse.value = await useCachedCategory(props.navigationId, route.query);

const breadCrumbResponse = ref();
breadCrumbResponse.value = await apiClient
    .invoke("readBreadcrumb get /breadcrumb/{id}", {
      pathParams: {
        id: props.navigationId,
      },
    })
    .catch(() => {
      console.error("Error while fetching breadcrumbs");
    });

if (breadCrumbResponse.value) {
  buildDynamicBreadcrumbs(breadCrumbResponse.value.data);
}

if (!categoryResponse.value) {
  console.error("[FrontendNavigationPage.vue]", 'Category could not be found or loaded.');
  throw createError({
    statusCode: 404,
    statusMessage: `Category could not be found or loaded.`,
  });
}

const { category } = useCategory(categoryResponse as Ref<Schemas["Category"]>);

const runtimeConfig = useRuntimeConfig();
const { locale } = useI18n();

useCmsHead(category, {
  mainShopTitle: runtimeConfig.public.general.shopTitle[locale.value],
});
</script>

<template>
  <div>
    <CmsPage v-if="category?.cmsPage" :content="category.cmsPage" />
  </div>
</template>